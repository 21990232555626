import React, {useEffect, useState} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {
    ClockCircleOutlined
} from '@ant-design/icons';
import axios from "axios";
import {fetchAllTasks} from "../../../service/task.service";
import Dropdown from "react-bootstrap/Dropdown";
import {Pagination} from "antd";
import TaskModal from "../../../task-modal";
import ReactPaginate from "react-paginate";
import {baseUrl} from "../../../service/baseUrl";

function CompleteTask2(props) {

    const [modal, setModal] = useState(false);
    const [totalItems, setTotalItems] = useState(0);

    const toggle = () => {
        setModal(!modal)
    };

    useEffect(()=>{
        getTasks()
    },[]);
    //http://admin-kpi.andqxai.uz/api/tasks?fromId.equals=1102&page=0&size=20
    const [query, setQuery] = useState(
        {
            filterType:"TO",
            page: 0,
            size: 20,
            'status.equals': 'COMPLETE',
            sort:['createdAt','desc']
        }
    );
    const [filenameFile, setFilenameFile] = useState('');
    const [tasks, setTasks] = useState([]);
    const [taskId, setTaskId] = useState([]);
    const [errorMessage, setErrorMessage] = useState([]);

    const getTasks = () => {
        console.log('query: ', query);
        fetchAllTasks(query)
            .then(res => {
                setTotalItems(res.headers['x-total-count']);
                setTasks(res.data);
                console.log('response from tasks: ', res.data)
            })
            .catch(err => {
                console.log('Can not get tasks: ', err)
                if (err.response.status >= 500){
                    setTimeout(()=>{
                        setErrorMessage(err.response.status + " "+err.response.statusText)
                    },300)
                }
            })
    };

    const getTaskId = (id) =>{
        axios.get(baseUrl+`/api/tasks/${id}`).then((res)=>{
            console.log(res.data);
            setTaskId(res.data);
            if (res.data.filename){
                setFilenameFile(res.data.filename);
            }
            else {setFilenameFile('')}
        }).catch((err)=>{
            console.log(err)
        })
    };

    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 9;

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(tasks.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(tasks.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, tasks]);


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % tasks.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            <div className="user_completeTask">
                <div className="container-fluid">
                    <div className="fort-task-count">
                        {errorMessage.length ? [errorMessage]  :<> Topshiriqlar soni: {totalItems}</>}
                    </div>
                    <div className="row">
                        {currentItems && currentItems.map((item, index)=>(
                            <div key={index} className="col-md-4">
                                <div className="card" onClick={()=>getTaskId(item.id)} >
                                    <div onClick={toggle} className="for-div">
                                        <div className="for-color"></div>
                                        <div className="for-data">
                                            <div className="for-title">{item.title}</div>
                                            <hr/>
                                            <div className="for-description">{item.description}</div>
                                            <div className="for-endDate">
                                                <ClockCircleOutlined />
                                                <p>{item.deadline.substring(0, 10)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="my-pagination">
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            pageCount={pageCount}
                            previousLabel="< previous"
                            renderOnZeroPageCount={null}
                            containerClassName="pagination"
                            pageLinkClassName="page-num"
                            previousLinkClassName="page-num"
                            nextLinkClassName="page-num"
                            activeLinkClassName="active"
                        />
                    </div>
                </div>
                <TaskModal modal={modal} toggle={toggle} filenameFile={filenameFile} task={taskId} />
            </div>
        </>
    );
}

export default CompleteTask2;