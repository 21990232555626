import React, {useState} from 'react';
import {Layout, Menu} from 'antd';
import {Link, Route, Routes, useNavigate} from "react-router-dom"
import "antd/dist/antd.css"
import "./mian_page.scss"
import {
    FileDoneOutlined,
    FileExcelOutlined,
    FileExclamationOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    TeamOutlined,
    UserAddOutlined,
    LineChartOutlined
} from '@ant-design/icons';

import EmployeeList from "./employeList/employeeList";
import CompletedTask from "./completeTask/CompletedTask";
import UncomletedTask from "./Uncompletetd/UncomletedTask";
import Passedtask from "./passedTask/Passedtask";
import AddUseMain from "./addUserMain/add_use_main";
import Statistic from "./statistic/Statistic";
import InProgs from "./inProgres/InProgs";

const {Header, Sider, Content} = Layout;
const {SubMenu} = Menu;

function MainPage(props) {


    const [collapsed, setCollapsed] = useState(false);
    const [burger, setBurger] = useState(false);
    const navigate = useNavigate();

    const logoutSystem = () => {
        localStorage.removeItem('token');
        navigate('/')
    };

    return (
        <>
            <Layout hasSider>
                <Sider className={collapsed ? "antLoyaut" : "ant-layout-sider-dark"} style={{
                    overflow: 'auto',
                    minHeight: 350,
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                }} trigger={null} collapsible collapsed={collapsed}>
                    <Link to="/main_page">
                        <div className={collapsed ? "for-logo1" : "for-logo"}>
                            <img className="img-logo" src="/images/Logo_white.png" alt=""/>
                        </div>
                    </Link>
                    <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                        {/*<SubMenu  key="sub1" icon={<DotChartOutlined />} title="Dashboard">*/}
                        {/*</SubMenu>*/}
                        <Menu.Item className="menu-main-item" key="1">
                            <Link className={collapsed ? "for-link2" : "for-link"} to="/main_page">
                                <LineChartOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                <p>Statistika</p>
                            </Link>
                        </Menu.Item>
                        <Menu.Item className="menu-main-item" key="2">
                            <Link className={collapsed ? "for-link2" : "for-link"} to="/main_page/employee">
                                <TeamOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                <p>Hodimlar ro'yhati</p>
                            </Link>
                        </Menu.Item>
                        <Menu.Item className="menu-main-item" key="3">
                            <Link className={collapsed ? "for-link2" : "for-link"} to="/main_page/in_progs">
                                <FileDoneOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                <p>Bajarilayotgan topshiriqlar</p>
                            </Link>
                        </Menu.Item>
                        <Menu.Item className="menu-main-item" key="4">
                            <Link className={collapsed ? "for-link2" : "for-link"} to="/main_page/completed">
                                <FileDoneOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                <p>Bajarilgan topshiriqlar</p>
                            </Link>
                        </Menu.Item>
                        <Menu.Item className="menu-main-item" key="5">
                            <Link className={collapsed ? "for-link2" : "for-link"} to="/main_page/passed">
                                <FileExclamationOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                <p>Kechikkan topshiriqlar</p>
                            </Link>
                        </Menu.Item>
                        <Menu.Item className="menu-main-item" key="6">
                            <Link className={collapsed ? "for-link2" : "for-link"} to="/main_page/uncompleted">
                                <FileExcelOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                <p>Bajarilmagan topshiriqlar</p>
                            </Link>
                        </Menu.Item>
                        <Menu.Item className="menu-main-item" key="7">
                            <Link className={collapsed ? "for-link2" : "for-link"} to="/main_page/add_user">
                                <UserAddOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                <p>Hodim qo'shish</p>
                            </Link>
                        </Menu.Item>
                        <Menu.Item className="menu-main-item" key="8">
                            <div className={collapsed ? "for-link2" : "for-link"} onClick={logoutSystem}>
                                <LogoutOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                <p>Chiqish</p>
                            </div>
                        </Menu.Item>
                    </Menu>
                </Sider>
                <div className="drop_menu">
                    <div className="body-collapse">
                        <div className="collapse collapse-horizontal" id="collapseWidthExample">
                            <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                                {/*<SubMenu  key="sub1" icon={<DotChartOutlined />} title="Dashboard">*/}
                                {/*</SubMenu>*/}
                                <Menu.Item className="menu-main-item" key="1">
                                    <Link className={collapsed ? "for-link2" : "for-link"} to="/main_page">
                                        <LineChartOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                        <p>Statistika</p>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item className="menu-main-item" key="2">
                                    <Link className={collapsed ? "for-link2" : "for-link"} to="/main_page/employee">
                                        <TeamOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                        <p>Hodimlar ro'yhati</p>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item className="menu-main-item" key="3">
                                    <Link className={collapsed ? "for-link2" : "for-link"} to="/main_page/in_progs">
                                        <FileDoneOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                        <p>Bajarilayotgan topshiriqlar</p>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item className="menu-main-item" key="4">
                                    <Link className={collapsed ? "for-link2" : "for-link"} to="/main_page/completed">
                                        <FileDoneOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                        <p>Bajarilgan topshiriqlar</p>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item className="menu-main-item" key="5">
                                    <Link className={collapsed ? "for-link2" : "for-link"} to="/main_page/passed">
                                        <FileExclamationOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                        <p>Kechikkan topshiriqlar</p>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item className="menu-main-item" key="6">
                                    <Link className={collapsed ? "for-link2" : "for-link"} to="/main_page/uncompleted">
                                        <FileExcelOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                        <p>Bajarilmagan topshiriqlar</p>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item className="menu-main-item" key="7">
                                    <Link className={collapsed ? "for-link2" : "for-link"} to="/main_page/add_user">
                                        <UserAddOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                        <p>Hodim qo'shish</p>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item className="menu-main-item" key="8">
                                    <div className={collapsed ? "for-link2" : "for-link"} onClick={logoutSystem}>
                                        <LogoutOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                        <p>Chiqish</p>
                                    </div>
                                </Menu.Item>
                            </Menu>
                        </div>
                    </div>
                </div>
                <Layout className="site-layout" style={{minHeight:"auto",overflow: 'auto',}}>
                    <Header
                        className="site-layout-background1"
                        style={{
                            padding: 0,
                        }}
                    >
                        <div className="burger_big">
                            <div className="burger_child">
                                <div className="for_burger" onClick={()=>setBurger(!burger)} type="button" data-bs-toggle="collapse"
                                     data-bs-target="#collapseWidthExample" aria-expanded="false"
                                     aria-controls="collapseWidthExample">
                                    {
                                        burger ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />
                                    }
                                </div>
                                <div className="my_profile justify-content-end">
                                    <div className="name">
                                        {localStorage.getItem('name')}
                                    </div>
                                    <div className="name">
                                        {localStorage.getItem('lastName')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="in_my_profile">
                            <div className="my_profile">
                                <div className="name">
                                    {localStorage.getItem('name')}
                                </div>
                                <div className="name">
                                    {localStorage.getItem('lastName')}
                                </div>
                            </div>
                        </div>
                    </Header>
                    <Content className="content_main shadow">
                        <div className="site-layout-background"
                             style={{
                                 overflow: 'auto',
                                 padding: 24,
                             }}>
                            <Routes>
                                <Route path="/"  element={<Statistic/>}/>
                                <Route path="/employee"  element={<EmployeeList/>}/>
                                <Route path="/in_progs"  element={<InProgs/>}/>
                                <Route path="/completed" element={<CompletedTask/>}/>
                                <Route path="/uncompleted" element={<UncomletedTask/>}/>
                                <Route path="/passed" element={<Passedtask/>}/>
                                <Route path="/add_user" element={<AddUseMain/>}/>
                                <Route path="/add_user/:id" element={<AddUseMain/>}/>
                            </Routes>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </>
    );
}

export default MainPage;