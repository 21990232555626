import React, {useEffect, useState} from 'react';
import trick from "../../../img/tick.png";
import read from "../../../img/read.png";
import ReactPaginate from "react-paginate";
import TaskModalGiven from "../../task-modal-given";
import {fetchAllTasks} from "../../service/task.service";
import axios from "axios";
import {baseUrl} from "../../service/baseUrl";
import TaskModalAdmin from "../../task_modal_admin";
const pageSize = 10;
function InProgs(props) {

    useEffect(() => {
        getTasks();
    }, []);

    const [totalItems, setTotalItems] = useState(0);
    const [tasks, setTasks] = useState([]);
    const [errorMessage, setErrorMessage] = useState([]);
    const [query, setQuery] = useState(
        {
            filterType: "FROM",
            page: 0,
            size:"100",
            'status.equals': 'IN_PROGRESS',
            sort:['createdAt','desc']
        }
    );

    const getTasks = () => {
        fetchAllTasks(query)
            .then(res => {
                setTotalItems(res.headers['x-total-count']);
                // console.log(totalItems, res);
                setTasks(res.data);
                console.log(res.data)
            })
            .catch(err => {
                console.log('Can not get tasks: ', err.response.statusText, errorMessage);
                if (err.response.status >= 500){
                    setTimeout(()=>{
                        setErrorMessage(err.response.status + " "+err.response.statusText)
                    },300)
                }
            });
    }
    const [modal, setModal] = useState(false);
    const [taskCurrent, setTaskCurrent] = useState([]);
    const [taskId, setTaskId] = useState([]);
    const [filenameFile, setFilenameFile] = useState('');
    const [salom, setSalom] = useState('');
    const toggle = (item) => setModal(!modal);


    const getTaskId = (id) => {
        axios.get(baseUrl+`/api/tasks/${id}`).then((res) => {

            setTaskId(res.data);
            if (res.data.filename){
                setFilenameFile(res.data.filename);
            }
            else {
                setFilenameFile('')
            }
            console.log()
        }).catch((err) => {
            console.log(err)
        })
    };


    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 9;

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(tasks.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(tasks.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, tasks]);


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % tasks.length;
        setItemOffset(newOffset);
    };


    return (
        <>
            <div className="my_tasks_new">
                <div className="container-fluid">
                    <div className="fort-task-count">
                        {errorMessage.length ? [errorMessage]  :<> Topshiriqlar soni: {totalItems}</>}
                    </div>
                    <div className="row">
                        {
                            currentItems && currentItems.map((item, index) => (
                                <div key={index} className="col-md-4 col-xl-4 mt-3 col-xxl-3" onClick={() => toggle(item)}>
                                    <div className="card" onClick={() => getTaskId(item.id)}>
                                        <div className="for_title">
                                            {item.title}
                                            {item.flow === "CREATED" ? <img className="read-img img-fluid" src={trick} alt="O'qildi"/> : <img className="read-img img-fluid" src={read} alt="O'qilmadi"/> }
                                        </div>
                                        <div className="for-description">
                                            {item.description}
                                        </div>
                                        <hr/>
                                        <div className="import_end">
                                            <div className="importance">
                                                {item.priority === "LOW" ? "Odatiy" : "Shoshilinch"}
                                            </div>
                                            <div className="end_date">
                                                {item.deadline.substring(0, 10)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="my-pagination">
                        {/*<div className="ant-pagination">*/}
                        {/*    {paginationTag()}*/}
                        {/*</div>*/}
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            pageCount={pageCount}
                            previousLabel="< previous"
                            renderOnZeroPageCount={null}
                            containerClassName="pagination"
                            pageLinkClassName="page-num"
                            previousLinkClassName="page-num"
                            nextLinkClassName="page-num"
                            activeLinkClassName="active"
                        />
                    </div>
                </div>
                <TaskModalAdmin modal={modal} toggle={toggle} filenameFile={filenameFile} task={taskId} />
            </div>
        </>
    );
}

export default InProgs;