import {axios, setAuthInterceptor} from "../service/axios.service";
import {toast, ToastContainer} from "react-toastify";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {baseUrl} from "../service/baseUrl";




let token;
let account;
let roles = [];
let errorStatus;
// let employee;



const hasAnyAuthorities = (authorities) => {
    return roles.includes(authorities)
};



const errorText = '';

const getToken = async (payload) => {
    return await new Promise((resolve, reject) => {
        axios
            .post(baseUrl+'/api/authenticate', payload)
            .then(async res => {
                console.log(res);
                if (res.status === 200) {
                    localStorage.setItem('token', res.data['id_token']);
                    token = res.data['id_token'];
                    setAuthInterceptor();
                    return resolve(token)
                }
            })
            .catch(async err => {
                errorStatus = err;
                return reject(err)
            })
    });
};
// const getEmployeeCurrent = async () => {
//     return await new Promise((resolve, reject) => {
//         axios
//             .get('/api/employees/current', getAuthHeader())
//             .then(async res => {
//                 console.log(res.data);
//                 employee = res.data;
//                 return resolve(account)
//             })
//             .catch(async err => {
//                 console.log('Can not get account: ', err.response);
//                 return reject(err)
//             })
//     })
// };

const getAccount = async () => {
    return await new Promise((resolve, reject) => {
        axios
            .get(baseUrl+'/api/account', getAuthHeader())
            .then(async res => {
                console.log(res);
                account = res.data;
                roles = account['authorities'];
                return resolve(account)
            })
            .catch(async err => {
                console.log('Can not get account: ', err.response);
                return reject(err)
            })
    })
};



const getSessionToken = () => {
    if (!token) {
        if (localStorage.getItem('token')) {
            token = localStorage.getItem('token')
        } else {
            return null
        }
    }
    return token;
}


const getAuthHeader = () => ({
    headers: {
        "Authorization": `Bearer ${getSessionToken()}`
    }
})




export {getToken, getSessionToken, getAccount,account, errorStatus,  hasAnyAuthorities}


