import React, {useEffect, useState} from 'react';
import axios from "axios";
import {baseUrl} from "../../service/baseUrl";
import fileDownload from "js-file-download";
import {
    CloudDownloadOutlined
} from '@ant-design/icons';
import {useParams,useNavigate} from 'react-router-dom'
import {ModalBody} from "reactstrap";
import {toast, ToastContainer} from "react-toastify";

function DetailTaskGiven(props) {

    const navigate = useNavigate();
    const {id} = useParams();
    const {detail, filenameFile} = props;

    const fieldResolver = (options) => detail && (options.type !== 'date') ? detail[options.key] : detail[options.key] && detail[options.key].substring(0, 10);

    const info = (options) => (
        <>
            <div className="two_text mt-3">
                <div className="for_name">{options.name}</div>
                <div className="for_data">{ fieldResolver(options)}</div>
            </div>
        </>)
    const taskOptions = [
        {name: 'Task ID :', key: 'id'},
        {name: 'Berilgan sana :', key: 'createdAt', type: 'date'},
        {name: 'Muddati :', key: 'deadline', type: 'date'},

    ]
    const taskOptions2 = [
        {name: 'Sarlavha:', key: 'title'},

    ];
    const taskOptions3 = [
        {name: 'Tavsifi:', key: 'description'},
        {name: 'Murakkablik :', key: 'difficulty'},
    ];

    const [valueState, setValueState] = useState("");

    useEffect(()=>{
        if (valueState != ''){
            download()
        }
    },[valueState]);

    const download = () => {
        axios.get(baseUrl + `/api/content/download/` + valueState, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, res.data.type);
                console.log(res)
            });
    };

    const [message, setMessage] = useState("");



    function notify() {
        if (message === "") {
        } else {toast.success(message);
            setMessage("")}
    }

    useEffect(()=>{
        notify();
        setMessage('')
    },[message]);

    const doneTask = () =>{
        axios.post(baseUrl+`/api/tasks/acceptFrom/${id}`).then(res=>{
            if (res.status === 200){
                console.log(res.data.status);
                if (res.data.status == 'OVERDUE'){
                    setMessage("Vazifa kechikkan topshiriqlar royhatiga qo'shildi. kechikkan topshiriqlar bolimiga o'ting")
                }
                else {
                    setMessage("Vazifa bajarilgan topshiriqlar royhatiga qo'shildi. bajarilgan topshiriqlar bolimiga o'ting")
                }
            }
        }).catch(err=>{
            console.log(err)
        })
    };

    return (
        <>
            <ToastContainer/>
            <div className="message">Topshiriq haqida to'liq ma'lumot</div>
            <div className="id_task_detail">
                <div className="id_task_detail_in">
                    {taskOptions.map(option => info(option))}
                    <button onClick={()=>doneTask()} className="done_task">Tugatish</button>
                </div>
                <div className="id_task_detail_in">
                    <div className="two_text mt-3">
                        <div className="for_name">Kim tomondan</div>
                        <div className="for_data">{detail.from && detail.from.firstname} {detail.from && detail.from.lastname}</div>
                    </div>
                    <div className="two_text mt-3">
                        <div className="for_name">File :</div>
                        <div className="for_data">
                            {filenameFile ? <button
                                className="pdf-file-download-deatil"
                                onClick={(e) => {setValueState(filenameFile)}}
                            >
                                <CloudDownloadOutlined/>
                                Download file
                            </button> : "File yo'q"}
                        </div>
                    </div>
                    {taskOptions2.map(option => info(option))}
                </div>
                <div className="id_task_detail_in">
                    <div className="two_text mt-3">
                        <div className="for_name">Muhimlik:</div>
                        <div className="for_data">{detail && detail.priority === "LOW" ?"Odatiy" : "Shoshilinch"}</div>
                    </div>
                    {taskOptions3.map(option => info(option))}
                </div>

            </div>
        </>
    );
}

export default DetailTaskGiven;