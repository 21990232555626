import React, {useEffect, useState, useRef} from 'react';
import "./postAssigmant.scss"
import { Table } from 'semantic-ui-react'
import {
    DeleteFilled,
    EditOutlined,
    LineChartOutlined
} from '@ant-design/icons';
import axios from "axios";
import {baseUrl} from "../../service/baseUrl";
import {useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";





function EmployeeList(props) {

    useEffect(()=>{
        getEmployeeList()
    },[]);

    const navigate = useNavigate();
    const [employeeList, setEmployeeList] = useState([]);

  const getEmployeeList = () =>{
      axios.get(baseUrl + '/api/employees').then(res=>{
          console.log(res.data);
          setEmployeeList(res.data)
      }).catch(err=>{
          console.log(err)
      })
  };

    const deleteEmployee = (id) =>{
        axios.delete(baseUrl+`/api/employees/${id}`).then(res=>{
            console.log(res);
            getEmployeeList()
            setModal(false)
        }).catch(err=>{
            console.log(err.response.statusText);
            if (err.response.status >= 500){
                setModal(false);
                setText(err.response.statusText+","+"qaytadan harakat qilib koring")
            }
            console.log(id)
        })
    };

    const [currentItemD, setCurrentItemD] = useState({});
    const [modal, setModal] = React.useState(false);
    const [text, setText] = useState(false);

    function notify() {
        if (text === ""){} else toast.error(text);
        setText("")
    };

    useEffect(() => {
        notify();
    }, [text]);

    const toggle = (item) => {
        setCurrentItemD(item.id);
        setModal(!modal)
    };
    return (
        <>
            <ToastContainer/>
        <Table celled selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Ism</Table.HeaderCell>
                    <Table.HeaderCell>Familiya</Table.HeaderCell>
                    <Table.HeaderCell>Otasini ismi</Table.HeaderCell>
                    <Table.HeaderCell>Bo'limi</Table.HeaderCell>
                    <Table.HeaderCell>Lavozimi</Table.HeaderCell>
                    <Table.HeaderCell>username</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {employeeList && employeeList.map((item, index)=>(
                    <Table.Row key={index}>
                        <Table.Cell>{item.firstname}</Table.Cell>
                        <Table.Cell>{item.lastname}</Table.Cell>
                        <Table.Cell>{item.fathername}</Table.Cell>
                        <Table.Cell>{item.department.name}</Table.Cell>
                        <Table.Cell>{item.position.name}</Table.Cell>
                        <Table.Cell>{item.username}</Table.Cell>
                        <Table.Cell className="table-for-options"><p><DeleteFilled onClick={() => toggle(item)}/></p> <p><EditOutlined onClick={()=>{navigate(`/main_page/add_user/${item.id}`)}}/></p></Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
            <Modal isOpen={modal} toggle={toggle}><ModalHeader toggle={toggle}>Malumot o'chirish</ModalHeader>
                <ModalBody>
                    <div className="paddingt">
                        Haqiqatdan ham malumotni o'chirishni hohlaysizmi
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => deleteEmployee(currentItemD)} color="primary">Ok</Button>
                </ModalFooter>
            </Modal>
            </>
    );
}

export default EmployeeList;