const MyInistialState = {
   profile:[]
};

export const MyReducers = (state = MyInistialState, action) => {
    switch (action.type) {
        case "GET_PROFILE" : {
            return {...state, profile: action.payload}
        }
        default: {
            return state
        }
    }
};