import {Input, Select, TextArea} from 'antd';
import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import "./userPostAssignmant.scss"
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import {CheckOutlined} from '@ant-design/icons';
import {axios} from "../../service/axios.service";
import {toast, ToastContainer} from 'react-toastify';
import {baseUrl} from "../../service/baseUrl";
import jsPDF from "jspdf"
import logo from "../../../img/Piter Kilder tiklanish debochasi_170519202532.pdf"

function PostTask(props) {

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [tos, setTos] = useState([]);
    const [deadline, setDeadline] = useState(new Date());
    const [priority, setPriority] = useState("");
    const [difficulty, setDifficulty] = useState("");
    const [file1, setFile1] = useState([]);
    const [id, setId] = useState("");
    const [userName, setUserName] = useState([]);
    const [success, setSuccess] = useState(false);
    const [text, setText] = useState(false);
    const {Option} = Select;


    useEffect(() => {
        getUser();
    }, []);
    const getUser = () => {
        axios.get(baseUrl+'/api/employees', {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('token')
            }
        }).then((res) => {
            console.log(res.data);
            setUserName(res.data)
        }).catch((err) => {
            console.log(err)
        })
    };
    const postTasks = () => {
        const file = new FormData();
        file.append('file', file1);
        const task = {
            title,
            flow: "CREATED",
            status: "IN_PROGRESS",
            tos,
            difficulty,
            deadline,
            priority,
            description
        }
        axios.post(baseUrl+'/api/tasks', task).then((res) => {
                setId(res.data.id);
                console.log(res);
                if (res.status === 201){
                    setSuccess(true)
                }
                    if (!file1){
                        axios.post(baseUrl+`/api/tasks/upload/${res.data.id}`,file).then(res=>{
                            console.log("uddalading", res);
                            setTos("");
                            setTitle("");
                            setDeadline("");
                            setDifficulty("");
                            setDescription("");
                            setId("");
                            setFile1("");
                            setPriority("");
                            setTos([])
                        }).catch(err=>{
                            console.log("uddlaolmading", err.response)
                        })
                    }
                    else if (file1){
                        axios.post(baseUrl+`/api/tasks/upload/${res.data.id}`,file).then(res=>{
                            console.log("uddalading", res);
                            setTos("");
                            setTitle("");
                            setDeadline("");
                            setDifficulty("");
                            setDescription("");
                            setId("");
                            setFile1("");
                            setPriority("");
                            setTos([])
                        }).catch(err=>{
                            console.log("uddlaolmading", err.response)
                        })
                    }
            }).catch((err) => {
                if (err.response.data.status >= 400){
                    setText(err.response.data.title)
                }
                else if (err.response.status >= 500){
                    setText( err.response.status+" "+err.response.statusText)
                }
                console.log("bu topshiriq berish err ", err.response);
        });
    };
    useEffect(() => {
        notify();
    }, [text]);
    useEffect(() => {
        setTimeout(() => {
            setSuccess(false)
        }, 2000);
    }, [success]);
    function notify() {
        if (text === ""){} else toast.error(text);
        setText("")
    };
    const handleChange = (value) => {
        setTos(value.map(it => ({id: it})))
    };

    // const pdfGenerate = () =>{
    //   const doc = new jsPDF('landscape','px','a4','false');
    //     doc.addImage(logo,'JPEG',65,20,500,400);
    //     doc.addPage();
    //     doc.text(60,60,'Name');
    //     doc.save('a.pdf')
    // };

    return (
        <div>
            <ToastContainer/>
            {
                success ? <div className="for_success">
                    Topshiriq muvaffaqiyatli bajarildi
                    <div className="for-icon">
                        <CheckOutlined/>
                    </div>
                </div> : ""
            }
            <div className="post_assignmant">
                <div className="for-post-text">
                    Topshiriq berish bo'limi
                    {/*<object type="text/html" data={file1} style={{width:'100%', height:'100%'}}/>*/}
                </div>
                <div className="user_post_task">
                    <div className="green_zone">
                        <div className="in_green_zone">
                            <div className="navbar">
                                Topshiriq berish
                            </div>
                            <hr/>
                            <div className="form">
                                <div className="d-block-big-media">
                                    <div className="first_input">
                                        <label htmlFor="">Topshiriq nomi</label>
                                        {/*<button onClick={pdfGenerate}>Download pdf</button>*/}
                                        <div>
                                            {/*<input onChange={(e) => setTitle(e.target.value)} placeholder="Topshiriq momi" type="text"/>*/}
                                            <Input value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Topshiriq momi" allowClear />
                                        </div>
                                    </div>
                                    <div className="first_input">
                                        <label className="mt-2" htmlFor="">To'liq malumot</label>
                                        <div>
                                            <textarea value={description} onChange={(e) => setDescription(e.target.value)} name="" id="" cols="30" rows="1" placeholder="To'liq malumot"></textarea>
                                        </div>
                                    </div>
                                    <div className="first_input">
                                        <label className="" htmlFor="">Fayl yuklash</label>
                                        <div><input   className="ant-input-affix-wrapper1 form-control"
                                                      onChange={(e) => setFile1(e.target.files[0])} type="file"/>
                                        </div>
                                    </div>
                                    <div className="first_input">
                                        <label className="mt-2" htmlFor="">Kimga</label>
                                        <div>
                                            <Select
                                                placeholder="Kimga"
                                                mode="tags"
                                                style={{width: '100%',}}
                                                tokenSeparators={[',']}
                                                onChange={handleChange}
                                            >
                                                {userName.map((item, index) => (
                                                    <Option key={index} value={item.id}>
                                                        {item.firstname}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                 <div className="d-block-big-media">
                                    <div className="first_input">
                                        <label htmlFor="">Tugatish muddati</label>
                                        <div>
                                            <DatePicker value={deadline} selected={deadline} dateFormat="dd/MM/yyy" minDate={new Date()} onChange={setDeadline} type="date"/>
                                        </div>
                                    </div>
                                    <div className="first_input">
                                        <label className="mt-2" htmlFor="">Muhimlik darajasini belgilash</label>
                                        <Select value={priority} className="d-flex select" onChange={setPriority}>
                                            <Option value="HIGH">Shoshilinch</Option>
                                            <Option value="LOW">Odatiy</Option>
                                        </Select>
                                    </div>
                                    <div className="first_input">
                                        <label className="mt-2" htmlFor="">Murakkablik darajasini belgilash</label>
                                        <div>
                                            <Select value={difficulty} className="d-flex" onChange={setDifficulty}>
                                                {
                                                    [1, 2, 3, 4, 5, 6, 7, 8, 9].map(i =>
                                                        <option value={i} key={i}>{i}</option>
                                                    )
                                                }
                                            </Select>

                                        </div>
                                    </div>
                                    <div className="first_input mt-4">
                                        <div className="for-add-button">
                                            <button onClick={postTasks}>Topshiriq berish</button>
                                            {/*()=>setSuccess(true)*/}
                                        </div>
                                    </div>
                                </div>
                                <div className="in-media">
                                   <div className="justify-content-center">
                                       <div>
                                           <div className="first_input">
                                               <label htmlFor="">Topshiriq nomi</label>
                                               {/*<button onClick={pdfGenerate}>Download pdf</button>*/}
                                               <div>
                                                   {/*<input onChange={(e) => setTitle(e.target.value)} placeholder="Topshiriq momi" type="text"/>*/}
                                                   <Input value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Topshiriq momi" allowClear />
                                               </div>
                                           </div>
                                           <div className="first_input">
                                               <label className="mt-2" htmlFor="">To'liq malumot</label>
                                               <div>
                                                   <textarea value={description} onChange={(e) => setDescription(e.target.value)} name="" id="" cols="30" rows="1" placeholder="To'liq malumot"></textarea>
                                               </div>
                                           </div>
                                           <div className="first_input">
                                               <label className="" htmlFor="">Fayl yuklash</label>
                                               <div><input   className="ant-input-affix-wrapper1 form-control"
                                                             onChange={(e) => setFile1(e.target.files[0])} type="file"/>
                                               </div>
                                           </div>
                                           <div className="first_input">
                                               <label className="mt-2" htmlFor="">Kimga</label>
                                               <div>
                                                   <Select
                                                       placeholder="Kimga"
                                                       mode="tags"
                                                       style={{width: '100%',}}
                                                       tokenSeparators={[',']}
                                                       onChange={handleChange}
                                                   >
                                                       {userName.map((item, index) => (
                                                           <Option key={index} value={item.id}>
                                                               {item.firstname}
                                                           </Option>
                                                       ))}
                                                   </Select>
                                               </div>
                                           </div>
                                       </div>
                                       <div>
                                           <div className="first_input">
                                               <label htmlFor="">Tugatish muddati</label>
                                               <div>
                                                   <DatePicker value={deadline} selected={deadline} dateFormat="dd/MM/yyy" minDate={new Date()} onChange={setDeadline} type="date"/>
                                               </div>
                                           </div>
                                           <div className="first_input">
                                               <label className="mt-2" htmlFor="">Muhimlik darajasini belgilash</label>
                                               <Select value={priority} className="d-flex select" onChange={setPriority}>
                                                   <Option value="HIGH">Shoshilinch</Option>
                                                   <Option value="LOW">Odatiy</Option>
                                               </Select>
                                           </div>
                                           <div className="first_input">
                                               <label className="mt-2" htmlFor="">Murakkablik darajasini belgilash</label>
                                               <div>
                                                   <Select value={difficulty} className="d-flex" onChange={setDifficulty}>
                                                       {
                                                           [1, 2, 3, 4, 5, 6, 7, 8, 9].map(i =>
                                                               <option value={i} key={i}>{i}</option>
                                                           )
                                                       }
                                                   </Select>

                                               </div>
                                           </div>
                                           <div className="first_input mt-4">
                                               <div className="for-add-button">
                                                   <button onClick={postTasks}>Topshiriq berish</button>
                                                   {/*()=>setSuccess(true)*/}
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PostTask;