import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";
import "./chat_message.scss"
import DetailTask from "./Detail_task";
import TelegramImg from "../../../img/telegramImg.png"
import {toast, ToastContainer} from "react-toastify";
import {queryResolver} from "../../service/request.util";
import {
    PaperClipOutlined,
    CloudDownloadOutlined
} from '@ant-design/icons';
import {baseUrl} from "../../service/baseUrl";
import fileDownload from 'js-file-download'

function ForMessage(props) {

    const {id} = useParams();
    const [detail, setDetail] = useState([]);
    const [filenameFile, setFilenameFile] = useState('');
    const [message, setMessage] = useState('');
    const [task, setTask] = useState({id:id});
    const [getMessageId, setGetMessageId] = useState([]);


    useEffect(()=>{
        axios.get(baseUrl+`/api/tasks/${id}`).then(res=>{
            // console.log(res.data);
            setDetail(res.data);
            if (res.data.filename){
                setFilenameFile(res.data.filename);
            }
        }).catch(err=>{
            console.log(err)
        });
    },[]);
    useEffect(()=>{
        getComments()
    },[]);

    const query = {
        page:0,
        size:20,
        "taskId.equals":id,
        sort:["createdAt","desc"],

    };

    const getComments = () =>{
        axios.get(baseUrl+'/api/comments'+queryResolver(query)).then(res=>{
            setGetMessageId(res.data.reverse());
            console.log(res.data)
        }).catch(err=>{
            console.log(err)
        })
    };

    const addMessage = (e) =>{
        if (message != ""){
               const newComment ={message,type:"MESSAGE", task};
               axios.post(baseUrl+'/api/comments',newComment).then(res=>{
                   console.log(res);
                   setMessage("");
                   getComments();
               }).catch(err=>{
                   console.log(err)

               })
           }
    };



    const [strue, setSTrue] = useState(false);
    const [comp, setComp] = useState("");
    const [filenameS, setFilenameS] = useState('');

    useEffect(() => {
         if (message.trim().length > 0) {
                setSTrue(true);
            } else {
                setSTrue(false)
            }
    });

    const getMessageIdForFile = () =>{
        if (filenameS != ""){
            const newComment = {message,type:"FILE", task};
            axios.post(baseUrl+`/api/comments/`,newComment).then(res=>{
                if (res.status === 201){
                    const file = new FormData();
                    file.append('file', filenameS);
                    axios.post(baseUrl+`/api/comments/upload/${res.data.id}`,file).then(res=>{
                        getComments();
                        setFilenameS("");
                        console.log(res)
                    }).catch(err=>{
                        console.log(err)
                    })
                }
            }).catch(err=>{
                console.log(err)
            })
        }
    };

    const errorClick = () =>{
        setComp("Habar yozilmagan!!, iltimos maqsadingizni yozing")
    };


    function notify() {
        if (comp === "") {
        } else toast.error(comp);
        setComp("")
    }

    const ref = useRef();


    useEffect(() => {
        notify()
    }, [comp]);

    const [userId, setUserId] = useState("");

    if (userId === "") {
        setUserId(localStorage.getItem('id'))
    }
    const [valueState, setValueState] = useState("");

    useEffect(()=>{
        if (valueState != ''){
            download()
        }
    },[valueState]);




        const download = () => {
            axios.get(baseUrl + `/api/content/download/` + valueState, {
                responseType: 'blob',
            })
                .then((res) => {
                    fileDownload(res.data, valueState);
                    console.log(res)
                });
        };


    return (
        <div className="for-message">
            <ToastContainer/>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <DetailTask filenameFile={filenameFile} detail={detail}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="message">So'zlashuv</div>
                            <div className="for_message_reality">
                                {getMessageId &&
                                getMessageId.map((item, index) => (
                                    <>
                                        {item.from.id == userId?(
                                            <div className="isYou">
                                                <p>
                                                {
                                                    item.filename?<button
                                                        className="pdf-file-download"
                                                        onClick={(e) => {setValueState(item.filename)}}
                                                    >
                                                        <CloudDownloadOutlined/>
                                                        Download file
                                                    </button>:null
                                                }
                                                {
                                                    item.message?
                                                        <>{item.message}</>
                                                     :null
                                                }
                                                </p>
                                            </div>
                                        ):(
                                            <div className="isMe">
                                                <p>
                                                {
                                                    item.filename?
                                                        <button className="pdf-file-download"
                                                                onClick={(e)=>{setValueState(item.filename);
                                                                    download(e)}} ><CloudDownloadOutlined />
                                                            Download file
                                                        </button>:null
                                                }
                                                {
                                                    item.message ?
                                                        <>{item.message}</>
                                                     :null
                                                }
                                                </p>
                                            </div>
                                        )}
                                    </>

                                ))}
                            </div>
                            <div className="message_place">
                                <div className="fileUpload">
                                    <label htmlFor="" className="label-Up"><PaperClipOutlined/></label>
                                    <div><input onChange={(e)=>setFilenameS(e.target.files[0])} className="fileUload" type="file"/></div>
                                </div>
                                <input value={message} onChange={(e)=>setMessage(e.target.value)} placeholder={filenameS ? filenameS.name : "Send message"} className="form-control sentI" type="text"/>
                                {
                                    strue || filenameS ?  <label htmlFor="" className="send_message" onClick={()=>{addMessage(); getMessageIdForFile();}}><img src={TelegramImg} alt="telegram"/></label>
                                        :
                                        <label htmlFor="" className="send_message" onClick={errorClick}><img src={TelegramImg} alt="telegram"/></label>
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForMessage;