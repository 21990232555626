import {axios} from "./axios.service";
import {queryResolver} from "./request.util";

const baseApi = 'https://admin-kpi.andqxai.uz/api/tasks';
const fetchAllTasks = async (query) => {
    return await axios
        .get(baseApi + queryResolver(query))
        .then(async res => await res)
};

export {fetchAllTasks}