import React, {useEffect, useState} from 'react';
import "./Authen.scss"
import {useNavigate} from "react-router-dom";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons';
import {
    account,
    employee,
    errorStatus,
    getAccount,
    getEmployeeCurrent,
    getToken,
    hasAnyAuthorities
} from "./auth-service";
import {ROLE_ADMIN} from "./auth.constants";
import {useDispatch} from "react-redux";
import {MyAction} from "../../components/redux/Action/MyActions"
import axios from "axios";
import {baseUrl} from "../service/baseUrl";



function Authentication(props) {
    const navigate = useNavigate();
    const [text, setText] = useState("");
    const [strue, setSTrue] = useState(false);
    const [forRole, setForRole] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (username.trim().length > 0 && password.trim().length > 3) {
            setSTrue(true);
        } else {
            setSTrue(false)
        }
    });
    const onSuccess = (route) => {
        const col12 = document.getElementById('col12');
        col12.style.width = "0px";
        col12.style.height = "0px";
        col12.style.borderRadius = "50%";
        col12.style.overflow = "hidden";
        col12.style.transition = ".3s linear";
        setTimeout(() => {
            navigate(route)
        }, 300);
        setUsername("");
        setPassword("")
    }

    const handleClick = () => {
        getToken({username, password}).then((res) => {
            getAccount(res).then((res) => {
                const isAdmin = hasAnyAuthorities(ROLE_ADMIN);
                if (!isAdmin) {
                    onSuccess('/user_page');
                    localStorage.setItem("name", account.firstName);
                    localStorage.setItem("lastName", account.lastName);
                    // console.log("Bu auth error",errorStatus);
                } else {
                    onSuccess('/main_page');
                    localStorage.setItem("name", account.firstName);
                    localStorage.setItem("lastName", account.lastName);
                    // console.log("Bu auth error",errorStatus);
                }

            }).catch(err=>{});
            axios.get(baseUrl+'/api/employees/current').then((res)=>{
                console.log(res.data.id);
                localStorage.setItem("id",res.data.id)
            }).catch(err=>{
                console.log(err)
            })
        }).catch(err=>{
            if (errorStatus.response.status >= 500){
                setText(errorStatus.response.status +" "+errorStatus.response.statusText)
            }
            else if (errorStatus.response.status >= 400){
                setText(errorStatus.response.status +" "+errorStatus.response.statusText)
            }
            console.log("Bu auth error",errorStatus.response);
        })
    };

    useEffect(() => {
        notify()
    }, [text]);

    function notify() {
        if (text === "") {
        } else toast.error(text);
        setText("")
    };

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [eyes, setEyes] = useState(false);

    const errorClick = () => {
        setText("Iltimos fo'rmani to'liq to'ldiring")
    };

    return (
        <div className="main-auth">

            <div className="container-fluid " id="col12">
                <div className="row">
                    <div className="col-md-12">
                        <ToastContainer/>
                       <div className="border_in"> <div>
                           <div className="main-style">
                               <div className="for-logo2">
                                   <img src="images/Logo_white.png" alt=""/>
                               </div>
                               <div className="for-text">
                                   Log In
                               </div>
                               <div className="for-input">
                                   <div>
                                       <input value={username} onChange={(e) => setUsername(e.target.value)}
                                              placeholder="Login" type="text"/>
                                   </div>
                                   <div className="in-eyes mt-3">
                                       <div className="for-eyes" onClick={() => setEyes(!eyes)}>
                                           {eyes ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
                                       </div>
                                       <input value={password} onChange={(e) => setPassword(e.target.value)} className="mt-3"
                                              placeholder="password" type={eyes ? "text" : "password"}/>
                                   </div>

                                   {
                                       strue ? <div onClick={() => {
                                           handleClick()
                                       }} className="mt-5 for-button">
                                           Log in
                                       </div> : <div onClick={errorClick} className="mt-5 for-button1">
                                           Fo'rmani to'ldiring
                                       </div>
                                   }

                               </div>
                           </div>
                       </div></div>


                    </div>
                </div>
            </div>
        </div>
    );
}

export default Authentication;