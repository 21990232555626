import axios from "axios";
import {getSessionToken} from "../Auth/auth-service";


const setAuthInterceptor = () => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${getSessionToken()}`;
    axios.interceptors.request.use(function (config) {
        config.headers.Authorization = `Bearer ${getSessionToken()}`;
        return config;
    });
}


export {axios, setAuthInterceptor}

