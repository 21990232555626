import React, {useEffect, useState} from 'react';
import "./add_user_main.scss"
import axios from "axios";
import {Select} from "antd";
import {CheckOutlined} from '@ant-design/icons';
import {baseUrl} from "../../service/baseUrl";
import {useParams,useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";


function AddUseMain(props) {

    const {id} = useParams();
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [fathername, setFathername] = useState("");

    const [position, setPosition] = useState({
        id:"",
        name:""
    });
    const [department, setDepartment] = useState({
        id:"",
        name:""
    });
    const [password, setPassword] = useState("");

    const [positionGet, setPositionGet] = useState([]);
    const [departmentGet, setDepartmentGet] = useState([]);

    const getPosition = () =>{
      axios.get(baseUrl+'/api/employee-positions').then(res=>{
          console.log(res.data);
          setPositionGet(res.data)
      }).catch(err=>{
          console.log(err)
      })
    };

    const getDepartment = () =>{
      axios.get(baseUrl+'/api/departments').then(res=>{
          console.log(res.data);
          setDepartmentGet(res.data)
      }).catch(err=>{
          console.log(err)
      })
    };
    const [text, setText] = useState(false);
    const postEmployee = () =>{
       if (id){
           axios.put(baseUrl + `/api/employees/${+id}`, {department,id:id, position, username, firstname, fathername, lastname, password}).then(res =>{
               console.log(res.status === 200);
               if (res.status === 200){
                   navigate('/main_page')
               }
           }).catch(err=> {
               console.log(err.response.statusText)
               if (err.response.status >= 500){
                   setText(err.response.statusText+"," + "Tekshirib qaytadan harakat qilib ko'ring")
               }
           })
       }
       else {
           axios.post(baseUrl+'/api/employees',{department, position, username, firstname, fathername,lastname,password}).then(res=>{
               console.log(res);
               console.log(department, position, username, firstname, fathername,lastname,password)
               if (res.status === 201){
                   setSuccess(true)
               }
           }).catch(err=>{
               console.log(err.response.status);
               if (err.response.status >= 500){
                   setText(err.response.status)
               }
           }).catch(err=>{console.log(err.response.status)})
       }
    };



    useEffect(()=>{
        getEmployeeList()
    },[]);


    useEffect(() => {
        notify();
    }, [text]);


    function notify() {
        if (text === ""){} else toast.error(text);
        setText("")
    };




    const getEmployeeList = () =>{
        axios.get(baseUrl + '/api/employees').then(res=>{
            console.log(res.data);
            res.data.forEach((item, index) => {
                if (item.id == id) {
                    setUsername(item.username);
                    setFirstName(item.firstname);
                    setLastName(item.lastname);
                    setFathername(item.fathername);
                    console.log(item);
                    console.log(res.data)
                }
            })
        }).catch(err=>{
            console.log(err)
        })
    };

    const valuePosition = (value,key ) =>{
        setPosition({...position,
            id:value,
            name:key.key
        })
    };

    const valueDepartment = (value,key) =>{
        setDepartment({...department,
            id:value,
            name:key.key
        })
    };

    useEffect(()=>{
        getPosition();
        getDepartment()
    },[]);
    const [success, setSuccess] = useState(false);
    const {Option} = Select;
    useEffect(() => {
        setTimeout(() => {
            setSuccess(false)
        }, 2000);
    }, [success]);



    return (

        <>
            <ToastContainer/>
            {
                success ? <div className="for_success">
                    Topshiriq muvaffaqiyatli bajarildi
                    <div className="for-icon">
                        <CheckOutlined/>
                    </div>
                </div> : ""
            }
            <div className="add_user_main">
                <div className="for-post-text">
                    Foydalanuvchi qo'shish bo'limi
                </div>
                <div className="user_employee_task">
                    <div className="green_zone">
                        <div className="in_green_zone">
                            <div className="navbar">
                                Foydalanuvchi qoshish
                            </div>
                            <hr/>
                            <div className="form">
                               <div className=" justify-content-between add_user_in_form_employee">
                                   <div className="in_in">
                                       <div className="first_input">
                                           <label htmlFor=""> Ismi</label>
                                           <div><input value={firstname} onChange={(e)=>setFirstName(e.target.value)} placeholder="Ismi" type="text"/></div>
                                       </div>
                                       <div className="first_input">
                                           <label className="mt-2" htmlFor="">Otasini ismi</label>
                                           <div><input value={fathername} onChange={(e)=>setFathername(e.target.value)} placeholder="Otasini ismi" type="text"/></div>
                                       </div>
                                       <div className="first_input">
                                           <label className="mt-2" htmlFor=""> Bo'limi nomi</label>
                                           <div>
                                               <Select className="d-flex select" onChange={valueDepartment}>
                                                   {departmentGet && departmentGet.map((item, index) => (
                                                       <Option key={item.name} value={item.id}>
                                                           {item.name}
                                                       </Option>
                                                   ))}
                                               </Select>
                                           </div>
                                       </div>
                                       <div className="first_input">
                                           <label className="mt-2" htmlFor="">lavozimi</label>
                                           <div>
                                               {/*<input placeholder="lavozimi" type="text"/>*/}

                                               <Select className="d-flex select" onChange={valuePosition}>
                                                   {positionGet && positionGet.map((item, index) => (
                                                       <Option key={item.name} value={item.id}>
                                                           {item.name}
                                                       </Option>
                                                   ))}
                                               </Select>
                                           </div>
                                       </div>
                                   </div>
                                   <div className="in_in_1">
                                       <div className="first_input">
                                           <label  htmlFor="">Familiya</label>
                                           <div><input value={lastname} onChange={(e)=>setLastName(e.target.value)} type="text" placeholder="Familiya"/></div>
                                       </div>
                                       <div className="first_input">
                                           <label className="mt-2" htmlFor="">Paroli</label>
                                           <div><input value={password} onChange={(e)=>setPassword(e.target.value)} type="text" placeholder="Paroli"/></div>
                                       </div>
                                       <div className="first_input">
                                           <label className="mt-2" htmlFor="">Foydalanuvchi nomi</label>
                                           <div><input value={username} onChange={(e)=>setUsername(e.target.value)} type="text" placeholder="Foydalanuvchi nomi"/></div>
                                       </div>
                                       <div className="for_add_button">
                                           {id ?  <button onClick={()=>{postEmployee()}}> Yangilash </button> : <button onClick={postEmployee}> Qo'shish</button>}
                                       </div>
                                   </div>
                               </div>
                                <div className="media-in-block">
                                  <div className="justify-content-center">
                                      <div className="justify-content-center">
                                          <div className="first_input">
                                              <label htmlFor=""> Ismi</label>
                                              <div><input value={firstname} onChange={(e)=>setFirstName(e.target.value)} placeholder="Ismi" type="text"/></div>
                                          </div>
                                          <div className="first_input">
                                              <label className="mt-2" htmlFor="">Otasini ismi</label>
                                              <div><input value={fathername} onChange={(e)=>setFathername(e.target.value)} placeholder="Otasini ismi" type="text"/></div>
                                          </div>
                                          <div className="first_input">
                                              <label className="mt-2" htmlFor=""> Bo'limi nomi</label>
                                              <div>
                                                  <Select className="d-flex select" onChange={valueDepartment}>
                                                      {departmentGet && departmentGet.map((item, index) => (
                                                          <Option key={item.name} value={item.id}>
                                                              {item.name}
                                                          </Option>
                                                      ))}
                                                  </Select>
                                              </div>
                                          </div>
                                          <div className="first_input">
                                              <label className="mt-2" htmlFor="">lavozimi</label>
                                              <div>
                                                  {/*<input placeholder="lavozimi" type="text"/>*/}

                                                  <Select className="d-flex select" onChange={valuePosition}>
                                                      {positionGet && positionGet.map((item, index) => (
                                                          <Option key={item.name} value={item.id}>
                                                              {item.name}
                                                          </Option>
                                                      ))}
                                                  </Select>
                                              </div>
                                          </div>
                                      </div>
                                      <div>
                                          <div className="first_input">
                                              <label  htmlFor="">Familiya</label>
                                              <div><input value={lastname} onChange={(e)=>setLastName(e.target.value)} type="text" placeholder="Familiya"/></div>
                                          </div>
                                          <div className="first_input">
                                              <label className="mt-2" htmlFor="">Paroli</label>
                                              <div><input value={password} onChange={(e)=>setPassword(e.target.value)} type="text" placeholder="Paroli"/></div>
                                          </div>
                                          <div className="first_input">
                                              <label className="mt-2" htmlFor="">Foydalanuvchi nomi</label>
                                              <div><input value={username} onChange={(e)=>setUsername(e.target.value)} type="text" placeholder="Foydalanuvchi nomi"/></div>
                                          </div>
                                          <div className="for_add_button">
                                              {id ?  <button onClick={()=>{postEmployee()}}> Yangilash </button> : <button onClick={postEmployee}> Qo'shish</button>}
                                          </div>
                                      </div>
                                  </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddUseMain;