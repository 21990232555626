import React, {useState} from 'react';
import {Link, Route, Routes, useNavigate} from "react-router-dom";
import useCollapse from 'react-collapsed'
import "antd/dist/antd.css"
import {
    LogoutOutlined,
    ArrowDownOutlined,
    HomeOutlined,
    FileAddOutlined,
    FileDoneOutlined,
    ArrowUpOutlined,
    FileExcelOutlined,
    FileExclamationOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined
} from '@ant-design/icons';
import "../main_page/mian_page.scss"
import {Layout, Menu} from "antd";
import CompleteTask from "./assiginmentsGiven/CompleteTask/Complete_Task";
import UnCompletedTask from "./assiginmentsGiven/UnCompleted_Task/UnCompleted_Task";
import PassedTask from "./assiginmentsGiven/PassedTask/Passed_Task";
import PostTask from "./Post_page/post_Task";
import MyTasks from "./assiginmentsGiven/myTasks/MyTasks";
import SubMenu from "antd/lib/menu/SubMenu";
import MyTasks2 from "./incomingAssignments/MyTasks/MyTasks2";
import CompleteTask2 from "./incomingAssignments/CompleteTask/CompleteTask2";
import PassedTask2 from "./incomingAssignments/PassedTask/PassedTask2";
import UnCompleteTask from "./incomingAssignments/UnCompleteTask/UnCompleteTask";
import ForMessage from "./for_message/For_message";
import {useSelector} from "react-redux";
import ForMessageGiven from "./for_message/for_message_given";
const { Header, Sider, Content } = Layout;


function UserPage(props) {

    const navigate = useNavigate();

    const logoutSystem = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('name');
        localStorage.removeItem('lastName');
        localStorage.removeItem('id');
        navigate('/')
    };

    const [collapsed, setCollapsed] = useState(false);
    const store = useSelector(state => state);
    const [burger, setBurger] = useState(false);
    const [isExpanded, setExpanded] = useState(false)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })

    return (
        <>
            <Layout hasSider>
                <Sider
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,
                        top: 0,
                        bottom: 0,
                    }}
                >
                    <Link to="/user_Page">
                        <div className={collapsed ? "for-logo1" :"for-logo"}>
                            <img className="img-logo" src="/images/Logo_white.png" alt=""/>
                        </div>
                    </Link>
                    <Menu  theme="dark" defaultSelectedKeys={['1']}  mode="inline">
                        <SubMenu  key="sub1" icon={<ArrowUpOutlined /> } title="Jo'natilgan">
                            <Menu.Item className="menu-main-item" key="1">
                                <Link className={collapsed ? "for-link2" : "for-link"} to="/user_Page">
                                    <HomeOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                    <p1>Bajarilayotgan </p1>
                                </Link>
                            </Menu.Item>
                            <Menu.Item className="menu-main-item" key="2">
                                <Link className={collapsed ? "for-link2" : "for-link"} to="/user_Page/completed_user">
                                    <FileDoneOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                    <p1>Bajarilgan </p1>
                                </Link>
                            </Menu.Item>
                            <Menu.Item className="menu-main-item" key="3">
                                <Link className={collapsed ? "for-link2" : "for-link"} to="/user_Page/passed">
                                    <FileExclamationOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                    <p1>Kechikkan</p1>
                                </Link>
                            </Menu.Item>
                            <Menu.Item className="menu-main-item" key="4">
                                <Link className={collapsed ? "for-link2" : "for-link"} to="/user_Page/uncompleted">
                                    <FileExcelOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                    <p1>Bajarilmagan </p1>
                                </Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu  key="sub2" icon={<ArrowDownOutlined />} title="Qabul qilingan">
                            <Menu.Item className="menu-main-item" key="5">
                                <Link className={collapsed ? "for-link2" : "for-link"} to="/user_Page/come/New_tasks">
                                    <HomeOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                    <p1>Bajarilayotgan </p1>
                                </Link>
                            </Menu.Item>
                            <Menu.Item className="menu-main-item" key="6">
                                <Link className={collapsed ? "for-link2" : "for-link"} to="/user_Page/come/Complete">
                                    <FileDoneOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                    <p1>Bajarilgan </p1>
                                </Link>
                            </Menu.Item>
                            <Menu.Item className="menu-main-item" key="7">
                                <Link className={collapsed ? "for-link2" : "for-link"} to="/user_Page/come/passed">
                                    <FileExclamationOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                    <p1>Kechikkan </p1>
                                </Link>
                            </Menu.Item>
                            <Menu.Item className="menu-main-item" key="8">
                                <Link className={collapsed ? "for-link2" : "for-link"} to="/user_Page/come/unComplete">
                                    <FileExcelOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                    <p1>Bajarilmagan </p1>
                                </Link>
                            </Menu.Item>
                        </SubMenu>
                        <Menu.Item className="menu-main-item menu-main-item1" key="9">
                            <Link className={collapsed ? "for-link2" : "for-link"} to="/user_Page/Post">
                                <FileAddOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                <p1>Topshiriq berish</p1>
                            </Link>
                        </Menu.Item>
                        <Menu.Item className="menu-main-item menu-main-item1" key="10">
                            <div className={collapsed ? "for-link2" : "for-link"} onClick={logoutSystem}>
                                <LogoutOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                <p1>Chiqish</p1>
                            </div>
                        </Menu.Item>

                    </Menu>
                </Sider>
                <div className="drop_menu">
                    <div className="body-collapse">
                        <div className="collapse collapse-horizontal" id="collapseWidthExample">
                            <Menu  theme="dark" defaultSelectedKeys={['1']}  mode="inline">
                                <SubMenu  key="sub1" icon={<ArrowUpOutlined /> } title="Jo'natilgan">
                                    <Menu.Item className="menu-main-item" key="1">
                                        <Link className={collapsed ? "for-link2" : "for-link"} to="/user_Page">
                                            <HomeOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                            <p1>Bajarilayotgan</p1>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item className="menu-main-item" key="2">
                                        <Link className={collapsed ? "for-link2" : "for-link"} to="/user_Page/completed_user">
                                            <FileDoneOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                            <p1>Bajarilgan</p1>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item className="menu-main-item" key="3">
                                        <Link className={collapsed ? "for-link2" : "for-link"} to="/user_Page/passed">
                                            <FileExclamationOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                            <p1>Kechikkan</p1>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item className="menu-main-item" key="4">
                                        <Link className={collapsed ? "for-link2" : "for-link"} to="/user_Page/uncompleted">
                                            <FileExcelOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                            <p1>Bajarilmagan</p1>
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>
                                <SubMenu  key="sub2" icon={<ArrowDownOutlined />} title="Qabul qilingan">
                                    <Menu.Item className="menu-main-item" key="5">
                                        <Link className={collapsed ? "for-link2" : "for-link"} to="/user_Page/come/New_tasks">
                                            <HomeOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                            <p1>Bajarilayotgan </p1>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item className="menu-main-item" key="6">
                                        <Link className={collapsed ? "for-link2" : "for-link"} to="/user_Page/come/Complete">
                                            <FileDoneOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                            <p1>Bajarilgan</p1>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item className="menu-main-item" key="7">
                                        <Link className={collapsed ? "for-link2" : "for-link"} to="/user_Page/come/passed">
                                            <FileExclamationOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                            <p1>Kechikkan</p1>
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item className="menu-main-item" key="8">
                                        <Link className={collapsed ? "for-link2" : "for-link"} to="/user_Page/come/unComplete">
                                            <FileExcelOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                            <p1>Bajarilmagan</p1>
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>
                                <Menu.Item className="menu-main-item menu-main-item1" key="9">
                                    <Link className={collapsed ? "for-link2" : "for-link"} to="/user_Page/Post">
                                        <FileAddOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                        <p1>Topshiriq berish</p1>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item className="menu-main-item menu-main-item1" key="10">
                                    <div className={collapsed ? "for-link2" : "for-link"} onClick={logoutSystem}>
                                        <LogoutOutlined className={collapsed ? "icon-antd2" : "icon-antd"}/>
                                        <p1>Chiqish</p1>
                                    </div>
                                </Menu.Item>

                            </Menu>
                        </div>
                    </div>
                </div>
                <Layout className="site-layout" style={{minHeight:"auto",overflow: 'auto',}}>
                    <Header
                        className="site-layout-background1"
                        style={{
                            padding: 0,
                        }}
                    >
                       <div className="burger_big">
                           <div className="burger_child">
                               <div className="for_burger" onClick={()=>setBurger(!burger)} type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseWidthExample" aria-expanded="false"
                                    aria-controls="collapseWidthExample">
                                   {
                                       burger ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />
                                   }
                               </div>
                               <div className="my_profile">
                                   <div className="name">
                                       {localStorage.getItem('name')}
                                   </div>
                                   <div className="name">
                                       {localStorage.getItem('lastName')}
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="in_my_profile">
                           <div className="my_profile">
                               <div className="name">
                                   {localStorage.getItem('name')}
                               </div>
                               <div className="name">
                                   {localStorage.getItem('lastName')}
                               </div>
                           </div>
                       </div>
                    </Header>
                    {/*<Header*/}
                    {/*    className="site-layout-background"*/}
                    {/*    style={{*/}
                    {/*        padding: 0,*/}
                    {/*    }}*/}
                    {/*/>*/}
                    <Content className="content_main">
                        <div
                            className="site-layout-background shadow"
                            style={{
                                overflow: 'auto',
                                padding: 24,
                            }}
                        >
                            <Routes>
                                <Route path="/completed_user" element={<CompleteTask/>}/>
                                {/*<Route path="/completed_user_task" element={<CompleteTask/>}/>*/}
                                <Route path="/uncompleted" element={<UnCompletedTask/>}/>
                                <Route path="/passed" element={<PassedTask/>}/>
                                <Route path="/Post" element={<PostTask/>}/>
                                <Route path="/" element={<MyTasks/>}/>
                                <Route path="/come/New_tasks" element={<MyTasks2/>}/>
                                <Route path="/come/Complete" element={<CompleteTask2/>}/>
                                <Route path="/come/passed" element={<PassedTask2/>}/>
                                <Route path="/come/unComplete" element={<UnCompleteTask/>}/>
                                <Route path="/chat/detail_task/:id" element={<ForMessage/>}/>
                                <Route path="/chat/detail_task_given/:id" element={<ForMessageGiven/>}/>
                            </Routes>

                        </div>
                    </Content>
                </Layout>
            </Layout>
        </>
    );
}

export default UserPage;