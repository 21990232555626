export const queryResolver = (query) => {
    let result = "";
    let index = 0;
    if (query) {
        Object.keys(query).forEach(key => {
            if (index === 0) {
                result += `?${key}=${resolveParam(query[key])}`
            } else {
                result += `&${key}=${resolveParam(query[key])}`
            }
            index++;
        })
    }
    return result;
};

const resolveParam = (param) => {
    let result = "";
    if (Array.isArray(param)) {
        for (let i = 0; i < param.length; i++) {
            result += param[i];
            if (i !== param.length-1) {
                result += ','
            }
        }
    } else {
        result = param
    }
    return result
};