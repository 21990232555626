import React from "react";
import {Route, Routes} from "react-router-dom";
import Authentication from "./components/Auth/Authentication";
import MainPage from "./components/main_page/main_page";
import UserPage from "./components/User_Page/User_Page";
import {setAuthInterceptor} from "./components/service/axios.service";
import './global.scss'

function App() {
    setAuthInterceptor();
    return (
        <Routes>
            <Route path="/" exact={true} element={<Authentication/>}/>
            <Route path="/main_page/*" exact={true} element={<MainPage/>}/>
            <Route path="/user_Page/*" exact={true} element={<UserPage/>}/>
        </Routes>
    );
}

export default App;
