import React, {useEffect, useRef, useState} from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import Highlighter from 'react-highlight-words';
import axios from "axios";
import {baseUrl} from "../../service/baseUrl";
import "./statistic.scss"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {queryResolver} from "../../service/request.util";
import * as XLSX from "xlsx";


function Statistic(props) {

    const  [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };



    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'Ism',
            dataIndex: 'employeeDTO',
            key: 'employeeDTO',
            width: '15%',
            ...getColumnSearchProps('employeeDTO'),
        },
        {
            title: 'Familiya',
            dataIndex: 'lastname',
            key: 'lastname',
            width: '15%',
            ...getColumnSearchProps('lastname'),
        },
        {
            title: "Bo'limi",
            dataIndex: 'depart',
            key: 'depart',
            ...getColumnSearchProps('depart'),
        },
        {
            title: "Lavozimi",
            dataIndex: 'pos',
            key: 'pos',
            ...getColumnSearchProps('pos'),
        },
        {
            title: "Topshiriqlar soni",
            dataIndex: 'all',
            key: 'all',
        },
        {
            title: "Yakunlanganlar soni",
            dataIndex: 'completed',
            key: 'completed',
        },
        {
            title: "Kechikkanlar soni",
            dataIndex: 'overdue',
            key: 'overdue',
        },
        {
            title: "Bajarilmaganlar soni",
            dataIndex: 'uncompleted',
            key: 'uncompleted',
        },
        {
            title: "KPI",
            dataIndex: 'completedDifficulty',
            key: 'completedDifficulty',
        },
    ];

    const newDate1 = new Date();
    const [startDate, setStartDate] = useState(new Date(newDate1.getFullYear(), newDate1.getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date());



    const [query, setQuery] = useState({
        "to":endDate.toISOString(),
        "from":startDate.toISOString(),
        sort: ["id","desc"],
    });

    // const newFunction = (data) =>{
    //     const newData = new Date(data.getFullYear(), data.getMonth(), 1);
    //     setStartDate(newData)
    // };

    useEffect(()=>{
        const params = {
            "to":endDate.toISOString(),
            "from":startDate.toISOString(),
            sort: ["id","desc"],
        };
         setQuery(params);
        getStatistic(params)

    },[startDate, endDate]);

    const [sortName, setSortName] = useState([]);

    const pushExcel = () =>{
        data && data.map((item)=>{
            var sortName1 = {
                Ism:item.employeeDTO,
                Familiya:item.lastname,
                ["Bo'limi"]:item.depart,
                Lavozimi:item.pos,
                Topshiriqlar:item.all,
                Bajarilganlar:item.completed,
                Kechikkanlar:item.overdue,
                Bajarilmaganlar:item.uncompleted,
                Ball:item.completedDifficulty
            };
            sortName.push(sortName1);
            setSortName([])
        });
    };

console.log(sortName);

    const getStatistic = (params) => {
        if (params == null) {
            params = query
        }
      axios.get(baseUrl+"/api/kpi/stats/employee"+queryResolver(params)).then((res)=>{
          if (res.data) {
              let newArr = [];
              res.data.forEach((item) => {
                  let newItem = item;
                  let newMas = item.employeeDTO;
                  let newDepart = item.employeeDTO.department;
                  let newPos = item.employeeDTO.position;
                  newItem.employeeDTO = item.employeeDTO.firstname;
                  newItem.lastname = newMas.lastname;
                  newItem.depart = newDepart.name;
                  newItem.pos = newPos.name;
                  newArr.push(newItem);
              });
              setData(newArr);
          }
      }).catch(err=>{
          console.log(err.response)
      })
    };

    useEffect(()=>{
        getStatistic()
    },[]);





    const testExcel = () => {
        const wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(sortName);
        XLSX.utils.book_append_sheet(wb,ws, "Statistika KPI");
        XLSX.writeFile(wb, "Statistika KPI.xlsx");
    };





    return (
        <>
            <div className="for_sort_date">
                <div className="div_in">
                    <DatePicker value={startDate} selected={startDate} dateFormat="dd/MM/yyy"  onChange={setStartDate} type="date"/>
                    <DatePicker value={endDate} selected={endDate} dateFormat="dd/MM/yyy"  onChange={setEndDate} type="date"/>
                </div>
                {/*<div className="none_datePicker">*/}
                {/*    <div><DatePicker value={startDate} selected={startDate} dateFormat="dd/MM/yyy"  onChange={setStartDate} type="date"/></div>*/}
                {/*    <div><DatePicker value={endDate} selected={endDate} dateFormat="dd/MM/yyy"  onChange={setEndDate} type="date"/></div>*/}
                {/*</div>*/}
                {/*<input onChange={(e)=>setStartDate(e.target.value)} type="date"/>*/}
                {/*<input onChange={(e)=>setEndDate(e.target.value)} type="date"/>*/}
                <button onClick={()=>{pushExcel(); testExcel()}}>save excel</button>
            </div>
            <Table className="mt-5" columns={columns} dataSource={data} />
        </>
    );
}

export default Statistic;