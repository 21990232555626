import React, {useEffect, useState} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Dropdown from "react-bootstrap/Dropdown";
import {Link} from "react-router-dom";
import axios from "axios";
import {baseUrl} from "./service/baseUrl";
import fileDownload from "js-file-download";
import {
    CloudDownloadOutlined,
    CommentOutlined
} from '@ant-design/icons';

function TaskModalGiven(props) {

    const {modal, toggle, task, filenameFile} = props;
    const taskOptions = [
        {name: 'Sarlavha:', key: 'title'},
        {name: 'Tavsifi:', key: 'description'},
        {name: 'Muddati:', key: 'deadline', type: 'date'},
        {name: 'Murakkablik:', key: 'difficulty'},
    ]
    const fieldResolver = (options) => task && (options.type !== 'date') ? task[options.key] : task[options.key] && task[options.key].substring(0, 10);

    const info = (options) => (<div>
        <div className="for_task_id">
            <div className="for_date">{options.name}</div>
            <div
                className="modal_title">{ fieldResolver(options)}</div>
        </div>
    </div>)



    const [valueState, setValueState] = useState("");

    useEffect(()=>{
        if (valueState != ''){
            download()
        }
    },[valueState]);

    const download = () => {
        axios.get(baseUrl + `/api/content/download/` + valueState, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, res.data.type);
                console.log(res)
            });
    };


    return (
        <div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Topshiriq haqida to'liq ma'lumot</ModalHeader>
                <ModalBody>
                    {taskOptions.map(option => info(option))}
                    <div className="for_task_id">
                        <div className="for_date">Muhimlik:</div>
                        <div className="modal_title">{task && task.priority === "LOW" ?"Odatiy" : "Shoshilinch"}</div>

                    </div>
                    <div className="for_task_id">
                        <div className="for_date">File:</div>
                        <div className="modal_title">{filenameFile ? <button
                            className="pdf-file-download-deatil"
                            onClick={(e) => {setValueState(filenameFile)}}
                        >
                            <CloudDownloadOutlined/>
                            Download file
                        </button> : "File yo'q"}</div>

                    </div>
                    <div className="for_task_id">
                        <div className="for_date">Masul shaxslar:</div>
                        <div className="modal_title">
                            <Dropdown className="dropdown">
                                <Dropdown.Toggle className="dropItem" id="dropdown-basic">
                                    Ustiga bosing
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {task.tos && task.tos.map((item, index) => (
                                        <Dropdown.Item key={index}>{item.firstname + " "}{item.lastname}</Dropdown.Item>))}

                                </Dropdown.Menu>
                            </Dropdown></div>
                    </div>
                </ModalBody>
                <ModalFooter className="justify-content-between">
                    <button className="btn btn-next-to-bac border-1"><Link to={`/user_page/chat/detail_task_given/${task.id}`}>Chat <CommentOutlined /></Link></button>
                    <button className="btn btn-next-to-bac " onClick={toggle}>Cancel</button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default TaskModalGiven;