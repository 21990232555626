import React, {useEffect, useState} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import "./passed-task.scss"
import {
    ClockCircleOutlined
} from '@ant-design/icons';
import ReactPaginate from "react-paginate";
import {fetchAllTasks} from "../../service/task.service";
import axios from "axios";
import {baseUrl} from "../../service/baseUrl";
import TaskModalAdmin from "../../task_modal_admin";


function Passedtask(props) {


    const toggle = () => {
        setModal(!modal)
    };

    const [modal, setModal] = useState(false);
    const [filenameFile, setFilenameFile] = useState('');
    const [taskId, setTaskId] = useState([]);
    const [tasks ,setTasks] = useState([]);
    const [totalItems, setTotalItems] = useState([])
    const [query, setQuery] = useState(
        {
            filterType:"FROM",
            page: 0,
            size:100,
            'status.equals': 'OVERDUE'
        }
    );

    const getTasks = () => {
        fetchAllTasks(query)
            .then(res => {
                setTotalItems(res.headers['x-total-count']);
                setTasks(res.data);
                console.log('response from tasks: ', res.data, totalItems)
            })
            .catch(err => console.log('Can not get tasks: ', err ))
    };

    const getTaskId = (id) =>{
        axios.get(baseUrl+`/api/tasks/${id}`).then((res)=>{
            console.log(res.data);
            setTaskId(res.data);
            if (res.data.filename){
                setFilenameFile(res.data.filename);
            }
            else {setFilenameFile('')}
        }).catch((err)=>{
            console.log(err)
        })
    };

    useEffect(()=>{
        getTasks()
    },[]);

    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 9;

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(tasks.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(tasks.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, tasks]);


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % tasks.length;
        setItemOffset(newOffset);
    };

    return (
        <div className="passed-task">
            <div className="container-fluid">
                <div className="fort-task-count">
                    Topshiriqlar soni: {totalItems}
                </div>
                <div className="row">
                    {currentItems && currentItems.map((item, index)=>(
                        <div key={index} className="col-md-4" onClick={()=>getTaskId(item.id)}>
                            <div className="card" onClick={toggle}>
                                <div className="for-div">
                                    <div className="for-color"></div>
                                    <div className="for-data">
                                        <div className="for-title">{item.title}</div>
                                        <hr/>
                                        <div className="for-description">{item.description}</div>
                                        <div className="for-endDate">
                                            <ClockCircleOutlined />
                                            <p>{item.deadline.substring(0,10)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="my-pagination">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        containerClassName="pagination"
                        pageLinkClassName="page-num"
                        previousLinkClassName="page-num"
                        nextLinkClassName="page-num"
                        activeLinkClassName="active"
                    />
                </div>
            </div>
            <TaskModalAdmin modal={modal} toggle={toggle} filenameFile={filenameFile} task={taskId}/>
        </div>
    );
}

export default Passedtask;